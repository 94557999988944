import request from "@/config/serve.js";
import { MAXSIZE, ZHID } from "@/common/constant/constant.js";

// 查询
export function getReviewList(params) {
  return request("get", "/honorAppReviewInfo/manual/list", {
    ...params,
  });
}
// 查询详情
export function queryGeneralDiff(params) {
  return request("get", "/app/queryGeneralDiff", params);
}
//应用版本审核
export function appVersionAudit(data) {
  return request("post", "/honorAppReviewInfo/manual/audit", data);
}
//批量审核
export function appVersionAuditBatch(data) {
  return request("post", "/honorAppReviewInfo/manual/batchAudit", data);
}
// 查询审核时的回退信息
export function getBackInfo(params) {
  return request("get", "/honorAppReviewInfo/backInfo", params);
}
// 查询审核时的下架信息
export function getOffShelfInfo(params) {
  return request("get", "/honorAppReviewInfo/unshelveInfo", params);
}
// 查询语言列表
export function getLanguageList(params) {
  return request("get", "/language/all", params);
}
// 查询国家、地区
export function getAreaList(params) {
  return request("get", "/app/selectCountry", params);
}
// 查询分类
export function getCategoryList(params) {
  return request("get", "/cmsCategory/list/hierarchy", {
    languageId: ZHID,
    deleted: false,
    ...params,
  });
}
// 查询标签
export function getLabelList(data) {
  return request("post", "/ozLabel/list", {
    page: 1,
    size: MAXSIZE,
    isDeleted: false,
    ...data,
  });
}
