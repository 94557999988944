<template>
  <div>
    <div class="fix-top">
      <el-button
        @click="handleCancel()"
        icon="el-icon-arrow-left"
        type="primary"
        >返回</el-button
      >
    </div>
    <div style="padding-top: 35px">
      <VersionInfo
        v-if="releaseType !== 2"
        ref="versionInfoRef"
        :operationType="operationType"
        :versionInfoData="versionInfoData"
      ></VersionInfo>
      <div v-else style="padding-top: 50px">预约应用无版本信息</div>
    </div>
  </div>
</template>
<script>
import VersionInfo from "../../appManagement/apkManagement/VersionInfo.vue"; // 版本信息
export default {
  components: { VersionInfo },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
    operationType: { default: "" },
  },
  created() {
    this.initData(this.detailData);
  },
  data() {
    return {
      appInfoData: {}, // 应用信息
      versionInfoData: {}, // 版本信息
      releaseType: 1, // 上架类型：1--普通，2--预约，3--内测，4--首发
    };
  },
  computed: {
    disabled() {
      return this.operationType === "view" ? true : false;
    },
  },
  methods: {
    handleCancel() {
      this.$emit("cancle");
    },
    initData(data) {
      const releaseType = data.releaseType || 1;
      this.releaseType = releaseType;
      const {
        appCategoryId,
        languageInfos = [],
        developerVo = {},
        versionVo = {},
        ...rest
      } = data;
      const localLanguage = (languageInfos || []).map((i) => i.languageId); // 可本地化语言的id列表
      const appClassify = (developerVo.appClassifyIds || "").split(",");
      const appInfoData = {
        ...rest,
        ...developerVo,
        releaseType, // 应用上架类型
        appName: data.title,
        appType: appCategoryId,
        languageInfos: this.formLanguageInfos(languageInfos),
        appCategory: appClassify[0] ? appClassify[0].split("|") : [],
        gameType: (appClassify[1] && parseInt(appClassify[1])) || 1,
        labels: (developerVo.labels || []).map((i) => i + ""),
        localLanguage,
      };
      this.appInfoData = appInfoData;

      const { extendInfo = {}, ozApk = {}, ...restVersion } = versionVo;
      this.versionInfoData = {
        ...rest,
        ...extendInfo,
        ...restVersion,
        releaseType, // 应用上架类型
        releaseArea: this.splitInt(versionVo.country),
        tariffType: this.splitInt(versionVo.inAppPayment, "string"),
        ageLevel: versionVo.ratingId,
        apkId: ozApk.id,
        apkForm: {
          ...ozApk,
          fileSha256: ozApk.fileSha256,
          sha256: ozApk.signSha256,
        },
        ersionPeriod: [extendInfo.startTime || "", extendInfo.endTime || ""],
        normalReleaseType: versionVo.releaseType || 1, //上架时间类型
        normalReleaseTime: versionVo.releaseTime,
      };
    },
    formLanguageInfos(lgList) {
      if (!lgList) {
        return [];
      }
      const list = lgList.map((lgObj) => {
        const { imageVos = [], videoVos = [], languageId, ...rest } = lgObj;
        const obj = { ...rest };
        obj.language = languageId;
        obj.imageForms = imageVos;
        obj.videoForms = videoVos;
        const apkImgList = [];
        const apkImgListLight = [];
        imageVos.forEach((imgObj) => {
          //imgType图片类型 图标:1，横向截图:2，竖向截图:3
          if (imgObj.imgType === 1) {
            obj.appIconLight = imgObj.accessPathLight;
            obj.appIcon = imgObj.accessPath;
          } else {
            apkImgList[imgObj.sort] = imgObj.accessPath;
            apkImgListLight[imgObj.sort] = imgObj.accessPathLight;
            obj.imgMode = imgObj.imgType === 3 ? "vertical" : "horizontal";
          }
        });
        obj.apkImgList = apkImgList;
        obj.apkImgListLight = apkImgListLight;
        videoVos.forEach((vObj) => {
          //videoType应用介绍视频横向:10，应用介绍视频竖向:11, 推荐视频:12，横向视屏封面:26，纵向视屏封面:27，推荐视屏封面:28
          if ([10, 11].includes(vObj.videoType)) {
            obj.videoMode = vObj.videoType === 11 ? "vertical" : "horizontal";
            obj.appVideoUrl = vObj.accessPath;
            obj.appVideoUrlLight = vObj.accessPathLight;
          } else if ([26, 27].includes(vObj.videoType)) {
            obj.videoMode = vObj.videoType === 27 ? "vertical" : "horizontal";
            obj.appVideoImg = vObj.accessPath;
            obj.appVideoImgLight = vObj.accessPathlight;
          } else if ([12].includes(vObj.videoType)) {
            obj.appPushVideoUrl = vObj.accessPath;
            obj.appPushVideoUrlLight = vObj.accessPathLight;
          } else if ([28].includes(vObj.videoType)) {
            obj.appPushVideoImg = vObj.accessPath;
            obj.appPushVideoImgLight = vObj.accessPathLight;
          }
        });
        return obj;
      });
      return list;
    },
    splitInt(str = "", stringType) {
      if (!str) {
        return [];
      }
      if (stringType) {
        return str.split("|").map((i) => String(i));
      } else {
        return str.split("|").map((i) => parseInt(i));
      }
    },
  },
  watch: {
    detailData(data) {
      this.initData(data);
    },
  },
};
</script>
<style scoped lang="scss">
.fix-top {
  position: fixed;
  z-index: 99;
  background: #fff;
  padding: 16px 0;
  width: 87%;
  top: 137px;
  display: flex;
  justify-content: space-between;
}
</style>
